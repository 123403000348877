<template>
  <section id="operations">
    <div class="operations-cnt">
      <div class="operations-title-cnt">
        <div class="operations-title">Event Management</div>
        <div class="line-divider"></div>
      </div>
      <div class="operations-desc">
        Meet your playmakers. The Event Management Team will work with you on
        every detail to produce a comprehensive event plan that is executed
        flawlessly.
      </div>
      <div class="rep-cnt">
        <div class="repBox half" v-for="rep in reps" :key="rep.name">
          <img
            :src="
              require('@/assets/images/VenueOverview/whoWeAre/reps/' +
                rep.image)
            "
            :alt="rep.name"
            class="repImg"
          />
          <div class="name-title">
            <div class="rep-name">{{ rep.name }}</div>
            <div class="rep-title">{{ rep.title }}</div>
          </div>
          <div class="rep-desc">
            {{ rep.description }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "operations",
  data() {
    return {
      reps: [
        {
          image: "rep-nicole.png",
          name: "Nicole Francis",
          title: "Senior Director of Private Events",
          description:
            "Nicole has an unparalleled knack for curating seamless and unforgettable experiences and has orchestrated a myriad of successful gatherings that blend elegance, innovation, and meticulous attention to detail at Allegiant Stadium.",
        },
        {
          image: "rep-jessica.png",
          name: "Jessica LaRoussa",
          title: "Corporate Event Manager",
          description:
            "Jessica is passionate about understanding the specific needs of each client, ensuring that their unique event goals are met with exceptional professionalism and expertise at Allegiant Stadium.",
        },
        // {
        //   image: "rep-allie.png",
        //   name: "Allie Laskaris",
        //   title: "Assistant Manager of Corporate Events",
        //   description:
        //     "Allie utilizes her creative problem-solving skills and detailed project management experience to enhance each client’s experience.",
        // },
        {
          image: "rep-brittney.png",
          name: "Brittney Yoshida",
          title: "Corporate Event Manager",
          description:
            "Brittney combines her expertise in planning a variety of events and her client relation skills to proactively guide clients through their Allegiant Stadium event experience.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
#operations {
  width: 100vw;
  height: 100vh;
  background: url("~@/assets/images/VenueOverview/whoWeAre/bg-operations.png")
    scroll no-repeat center center;
  background-size: cover;
  @media (max-width: $lg) {
    padding: 150px 0 0 0;
    height: auto;
  }
  .operations-cnt {
    position: relative;
    display: flex;
    height: 100%;
    overflow: auto;
    flex-direction: column;
    justify-content: flex-start;
    padding: 160px 30px 160px 298px;
    .operations-title-cnt {
      display: inline-flex;
      flex-wrap: wrap;
      align-self: flex-start;
      margin-bottom: 25px;
      .operations-title {
        width: 100%;
        margin-bottom: 25px;
        font-size: 35px;
        font-family: "ArtegraSans-Bold";
        line-height: 30px;
        letter-spacing: 2.19px;
        text-transform: uppercase;
        color: #ffffff;
        @media (max-width: $sm) {
          font-size: 32px;
        }
      }
      .line-divider {
        position: relative;
        width: 100%;
        height: 2px;
        background: #ffffff;
        box-shadow: 0 0 9px 2px #ffffff;
      }
    }
    .operations-desc {
      max-width: 653px;
      font-size: 16px;
      font-family: "ArtegraSans-Regular";
      line-height: 23px;
      letter-spacing: 1.07px;
      color: #ffffff;
      @media (min-width: 2000px) {
        font-size: 26px;
        line-height: 37px;
        max-width: 50%;
      }
    }
    .rep-cnt {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      gap: 58px;
      .repBox {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: calc(100% / 3 - 58px);
        @media (max-width: $sm) {
          flex-basis: calc(50% - 58px);
        }
        &.bottom-row {
          margin: 50px 0;
        }
        .repImg {
          margin-bottom: 10px;
          width: 200px;
          @media (min-width: 2000px) {
            width: 300px;
          }
        }
        .rep-name {
          display: block;
          width: 100%;
          font-size: 16px;
          font-family: "ArtegraSans-Bold";
          letter-spacing: 1px;
          line-height: 22px;
          text-align: center;
          text-transform: uppercase;
          color: #ffffff;
          @media (min-width: 2000px) {
            font-size: 30px;
            line-height: 50px;
          }
          @media (max-width: $xl) {
            font-size: 14px;
          }
          @media (max-width: $md) {
            font-size: 12px;
          }
          @media (max-width: $sm) {
            line-height: 22px;
          }
        }
        .rep-title {
          display: block;
          width: 100%;
          font-size: 14px;
          line-height: 18px;
          font-family: "ArtegraSans-Bold";
          letter-spacing: 0.88px;
          text-align: center;
          text-transform: uppercase;
          color: #989898;
          @media (min-width: 2000px) {
            font-size: 26px;
            line-height: 37px;
          }
          @media (max-width: $xl) {
            font-size: 14px;
          }
          @media (max-width: $lg) {
            font-size: 12px;
          }
          @media (max-width: $md) {
            font-size: 10px;
            line-height: 12px;
          }
        }
        .rep-desc {
          display: block;
          width: 100%;
          margin-top: 20px;
          font-size: 19px;
          font-family: "ArtegraSans-Regular";
          line-height: 25px;
          letter-spacing: 1.19px;
          text-align: center;
          color: #ffffff;
          @media (min-width: 2000px) {
            font-size: 26px;
            line-height: 37px;
          }
          @media (max-width: $xl) {
            font-size: 14px;
            line-height: 20px;
          }
          @media (max-width: $md) {
            font-size: 12px;
            line-height: 18px;
          }
        }
      }
    }
  }
}
</style>
